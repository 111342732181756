import { authorized, unauthorized } from '../axios';
import {
  CONTACT_US, FILTER_PROPERTY,
  GET_DASHBOARD,
  GET_HOME_PROPERTY,
  GET_PROPERTY,
  GET_USER_PROPERTY,
  NEXT_OF_KIN,
  USER_DATA,
} from '../service-routes';
import { ContactUs, NextOfKin, UserData } from '../constant/serviceType';

export const getDashboard = (id: string | number) => {
  return unauthorized.get(`${GET_DASHBOARD}/${id}`);
};

export const getProperty = (number: number) => {
  return authorized.get(GET_PROPERTY, {
    params: { page: number },
  });
};

export const getSingleProperty = (id: string | number) => {
  return authorized.get(`${GET_PROPERTY}/${id}`);
};

export const postNextOfKin = (payload: NextOfKin) => {
  return authorized.post(NEXT_OF_KIN, payload);
};

export const getNextOfKin = (id: string | number) => {
  return authorized.get( `${NEXT_OF_KIN}/${id}`);
};

export const updateNextOfKin = (payload: NextOfKin) => {
  return authorized.put(NEXT_OF_KIN, payload);
};

export const postUserData = (payload: UserData, id: string | number) => {
  return authorized.post(`${USER_DATA}/${id}`, payload);
};

export const getUserData = (id: string | number) => {
  return authorized.get( `${USER_DATA}/${id}`);
};

export const postContactUs = (payload: ContactUs) => {
  return authorized.post(CONTACT_US, payload);
};

export const getFilteredData = (filter: any) => {
  return authorized.get(FILTER_PROPERTY, { params: filter });
};

export const getFeaturedProperty = (number: number) => {
  return authorized.get(GET_HOME_PROPERTY, {
    params: { page: number },
  });
};

export const getUserProperty = (id: string | number) => {
  return authorized.get(`${GET_USER_PROPERTY}/${id}`);
};

