import { getFilteredData, getProperty, getUserProperty } from '../services/userService';
import { useRef, useState } from 'react';
import { ObjectType } from '../constant/serviceType';

export const useProperty = ( ) => {
  const [property, setProperty] = useState<[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [paginate, setPaginate] = useState<ObjectType>({});
  const didFetch = useRef<boolean>(false);
  const getPropertyCoOwnData = async (page: number) => {
    if (didFetch.current) return;
    let userResponse = null;
    setLoading(true);
    try {
      const response = await getProperty(page);
      console.log('PROPERTY', response);
      userResponse = response?.data?.data;
      if (userResponse) {
        setProperty(userResponse);
        setPaginate(response?.data?.pagination);
        setLoading(false);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        return { success: true };
      }
    } catch (e: any) {
      console.log(e?.response?.data?.message);
      setLoading(false);
      return { success: false, error: e?.response?.data?.message };
    }

    return userResponse;
  };
  const getUserPropertyData = async (id: string | number) => {
    if (didFetch.current) return;
    let userResponse = null;
    setLoading(true);
    try {
      const response = await getUserProperty(id);
      console.log('PROPERTY', response);
      userResponse = response?.data?.data;
      if (userResponse) {
        setProperty(userResponse);
        setLoading(false);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        return { success: true };
      }
    } catch (e: any) {
      console.log(e.response.data.message);
      setLoading(false);
      return { success: false, error: e.response.data.message };
    }

    return userResponse;
  };
  const getFilteredPropertyData = async (params: any) => {
    if (didFetch.current) return;
    let userResponse = null;
    setLoading(true);
    setError(null);
    try {
      const response = await getFilteredData(params);
      // console.log('PROPERTY', response);
      userResponse = response?.data?.data;
      if (userResponse) {
        setProperty(userResponse);
        setPaginate(response?.data?.pagination);
        setLoading(false);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        return { success: true };
      }
    } catch (e: any) {
      // console.log(e?.response?.data?.message);
      setLoading(false);
      setError(e?.response?.data?.message);
      return { success: false, error: e?.response?.data?.message };
    }

    return userResponse;
  };

  return { getPropertyCoOwnData, getFilteredPropertyData, paginate, loading, property, getUserPropertyData, error };
};
