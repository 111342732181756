import { Gps, Home, Location, SearchNormal1, UsdCoin } from 'iconsax-react';
import React, { useState } from 'react';
import { formatCurrency2 } from '../../Utils/currencyFormat';
import { ObjectType } from '../../Services/constant/serviceType';

export const CustomSearch = ({ onSearch } : any) => {
  const [data, setData] = useState<ObjectType>({
    max_price: '',
    min_price: '',
  });

  const onChangeInput = (e: any) => {
    console.log(e.target.name, e.target.value);
    const { name, value } = e.target;
    if (name === 'min_price') {
      const sanitizedValue = value.replace(/[₦,]/g, '');
      setData({ ...data, [name]: sanitizedValue });
      return;
    }
    if (name === 'max_price') {
      const sanitizedValue = value.replace(/[₦,]/g, '');
      setData({ ...data, [name]: sanitizedValue });
      return;
    }
    setData({ ...data, [name]: value });
  };
  // const priceRange = [
  //   { min_price: 10000000, max_price:10000000 },
  //   { min_price: 20000000, max_price:20000000 },
  //   { min_price: 30000000, max_price:30000000 },
  //
  // ];

  const handleSearch = () => {
    onSearch(data);
  };
  return (
        <>
            <div className='flex justify-center lg:flex-row flex-wrap gap-2.5'>
                <div className='flex items-center bg-white border-custom-green border rounded-full px-2.5 py-2'>
                    <Location size="18" color="#286722"/>
                    <input type='text' name={'location'} placeholder='Lagos Nigeria' onChange={onChangeInput}
                           value={data?.location}
                           className='focus-visible:outline-0 border-0 px-2 text-xs text-custom'/>
                    <Gps size="18" color="#D9D9D9"/>
                </div>
                <div className='flex items-center bg-white border-custom-green border rounded-full px-2.5 py-2'>
                    <Home size="18" color="#286722"/>
                    <select name={'type'} value={data?.type || ''} onChange={onChangeInput}
                            className='focus-visible:outline-0 border-0 text-xs px-2 text-custom'>
                        <option value='' disabled={true}>
                            Property type
                        </option>
                        <option value='0'>
                            Buy
                        </option>
                        <option value='1'>
                            Co-Own
                        </option>
                    </select>
                </div>
                <div className='flex items-center bg-white border-custom-green border rounded-full px-2.5 py-2'>
                    <UsdCoin size="18" color="#286722"/>
                    <input type='text' name={'min_price'} placeholder='minimum price' onChange={onChangeInput}
                           value={formatCurrency2(data?.min_price)}
                           className='focus-visible:outline-0 border-0 px-2 text-xs text-custom'/>
                    <Gps size="18" color="#D9D9D9"/>
                </div>
                <div className='flex items-center bg-white border-custom-green border rounded-full px-2.5 py-2'>
                    <UsdCoin size="18" color="#286722"/>
                    <input type='text' name={'max_price'} placeholder='maximum price' onChange={onChangeInput}
                           value={formatCurrency2(data?.max_price)}
                           className='focus-visible:outline-0 border-0 px-2 text-xs text-custom'/>
                    {/*<Gps size="18" color="#D9D9D9"/>*/}
                </div>
                {/*<div className='flex items-center bg-white border-custom-green border rounded-full px-2.5 py-2'>*/}
                {/*    <select name='priceRange' value={`${data?.min_price}-${data?.max_price}`} onChange={(e) => {*/}
                {/*      const [minPrice, maxPrice] = e.target.value.split('-').map(Number);*/}
                {/*      console.log(minPrice, maxPrice);// Splits the value and converts to numbers*/}
                {/*      setData({ ...data, min_price: minPrice, max_price: maxPrice });*/}
                {/*    }} className='focus-visible:outline-0 border-0 text-xs px-2 text-custom'>*/}
                {/*        <option value='' disabled={true}>*/}
                {/*            Price Range*/}
                {/*        </option>*/}
                {/*        {*/}
                {/*            priceRange.map((price) => (*/}
                {/*                <option value={`${price.min_price}-${price.max_price}`}>*/}
                {/*                    {formatCurrency(price.min_price)} - {formatCurrency(price.max_price)}*/}
                {/*                </option>*/}
                {/*            ))*/}
                {/*        }*/}
                {/*    </select>*/}
                {/*</div>*/}
                <div onClick={handleSearch}
                     className='flex gap-2 items-center bg-custom-midnightgreen border-custom-green border rounded-lg px-4 py-2 cursor-pointer'>
                    <SearchNormal1 size="18" color="#ffffff"/>
                    <p className='text-white text-xs md:text-sm'>Search</p>
                </div>
            </div>
        </>
  );
};
