import React, { useEffect, useState } from 'react';
import { ObjectType } from '../../Services/constant/serviceType';
import ReactPaginate from 'react-paginate';
import { ReactComponent as Nexticon } from '../../Assets/Icons/nextarrw.svg';
import { ReactComponent as Previcon } from '../../Assets/Icons/prevarrw.svg';
import '../../Stylesheets/pagination.css';

interface Props {
  paginate: ObjectType,
  getPropertyCoOwnData: (number: number) => void
}

export const Pagination = ({ paginate, getPropertyCoOwnData }: Props) => {
  const [currentPage, setCurrentPage] = useState(paginate?.currentPage || 1);
  console.log('log', currentPage);
  const itemsPerPage = paginate?.itemsPerPage || 10;

  useEffect(() => {
    if (paginate?.currentPage) {
      setCurrentPage(paginate.currentPage);
    }
  }, [paginate?.currentPage]);

  const pageCount = paginate?.totalPages || Math.ceil(paginate?.totalItems / itemsPerPage);

  const handlePageClick = (event: any) => {
    const selectedPage = event.selected + 1;
    console.log('selectpage', selectedPage);
    setCurrentPage(selectedPage);
    getPropertyCoOwnData(selectedPage);
  };
  return (
      <>
          <div>
              <ReactPaginate
                  breakLabel="..."
                  previousLabel={<Previcon/>}
                  nextLabel={<Nexticon/>}
                  onPageChange={handlePageClick}
                  containerClassName={'Paginationbtn'}
                  previousLinkClassName={'prevbtn'}
                  pageClassName={'pageli'}
                  nextLinkClassName={'nextbtn'}
                  disabledClassName={'paginationdisabled'}
                  activeClassName={'paginationactive'}
                  breakClassName={'breakli'}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  renderOnZeroPageCount={null}
              />
          </div>
      </>
  );
};
