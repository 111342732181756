import { getFeaturedProperty, getFilteredData, postContactUs } from '../services/userService';
import { useRef, useState } from 'react';
import { ContactUs, ObjectType } from '../constant/serviceType';
import { useToast } from '../../Store/NotificationContext';

export const useLandingPage = () => {
  const [property, setProperty] = useState<[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [paginate, setPaginate] = useState<ObjectType>({});
  const didFetch = useRef<boolean>(false);
  const { showToast } = useToast();
  const ContactUsRequest = async (payload: ContactUs) => {
    if (didFetch.current) return;
    let userResponse = null;
    setLoading(true);
    try {
      const response = await postContactUs(payload);
      userResponse = response.data.status;
      if (userResponse) {
        setLoading(false);
        showToast('Success', response.data.message, 'success');
        // eslint-disable-next-line @typescript-eslint/naming-convention
        return { success: true };
      }
    } catch (e: any) {
      // console.log(e.response.data.message);
      setLoading(false);
      showToast('Error', e.response.data.message, 'error');
      return { success: false, error: e.response.data.message };
    }

    return userResponse;
  };
  const GetHomeProperty = async (page: number) => {
    if (didFetch.current) return;
    let userResponse = null;
    setLoading(true);
    try {
      const response = await getFeaturedProperty(page);
      userResponse = response?.data?.data;
      if (userResponse) {
        setLoading(false);
        setPaginate(response?.data?.pagination);
        setProperty(response?.data?.data);
        return { success: true };
      }
    } catch (e: any) {
      setLoading(false);
      return { success: false, error: e.response.data.message };
    }

    return userResponse;
  };
  const getFilteredHomeProperty = async (params: any) => {
    if (didFetch.current) return;
    let userResponse = null;
    setLoading(true);
    setError(null);
    try {
      const response = await getFilteredData(params);
      userResponse = response?.data?.data;
      if (userResponse) {
        setLoading(false);
        setPaginate(response?.data?.pagination);
        setProperty(response?.data?.data);
        return { success: true };
      }
    } catch (e: any) {
      setLoading(false);
      setError(e?.response?.data?.message);
      return { success: false, error: e?.response?.data?.message };
    }

    return userResponse;
  };

  return { loading, ContactUsRequest, GetHomeProperty, paginate, property, getFilteredHomeProperty, error };
};
