import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../Components/Layout/Footer';
import Tabs from '../../Components/Tab/FeaturedPropertyTab';
// import { RentIcon } from '../../Assets/Icons/rentIcon';
import { BuyIcon } from '../../Assets/Icons/buyIcon';
import { CoOwnIcon } from '../../Assets/Icons/coOwnIcon';
// import { Gps, Home, Location, SearchNormal1, UsdCoin } from 'iconsax-react';
import { ReactComponent as Bed } from '../../Assets/Icons/bedcardIcon.svg';
import { ReactComponent as Shower } from '../../Assets/Icons/ShowerIcon.svg';
import { ReactComponent as Toilet } from '../../Assets/Icons/toiletIcon.svg';
import { useLandingPage } from '../../Services/hooks/landingpage';
import { formatCurrency } from '../../Utils/currencyFormat';
import { PageLoader } from '../../Components/Loader/pageLoader';
import { CustomSearch } from '../../Components/Form/CustomSearch';
import { Pagination } from '../../Components/Pagination/pagination';


function  Property() {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const { GetHomeProperty, property, loading: pageLoading, getFilteredHomeProperty, error, paginate } = useLandingPage();
  const tabs = [
    // { id: 'rent', label: 'Rent', icon: RentIcon },
    { id: 'buy', label: 'Buy', icon: BuyIcon },
    { id: 'coOwn', label: 'Co-own', icon: CoOwnIcon },
  ];
  const handleTabChange = (index: number) => {
    console.log(index);
    setActiveTabIndex(index);
    console.log(`Selected Tab: ${tabs[index].id}`);
    const tabLabel = tabs[index].id;
    console.log(`Selected Tab: ${tabLabel}`);
  };
  const handleSearch = async (params: any) => {
    await getFilteredHomeProperty(params);
  };
  useEffect(() => {
    GetHomeProperty(1);
  }, []);
  return (
      <div className={'py-10'}>
        <div className='text-center '>
          {/*<h1 className='text-2xl md:text-3xl lg:text-4xl font-bold py-2'>*/}
          {/*            Featured properties*/}
          {/*</h1>*/}
          <div className={'lg:w-10/12 mx-auto bg-[#D9FCD5] rounded-xl py-7 flex flex-col gap-3.5'}>
            <div className={'flex justify-center py-2'}>
              <Tabs tabs={tabs} activeTabIndex={activeTabIndex} onTabChange={handleTabChange}/>
            </div>
            <CustomSearch onSearch={handleSearch}/>
            {error && (<p className='text-sm text-red-900 italic pt-1'>
              {error}
            </p>)}
          </div>
        </div>
        {pageLoading ?
            <div className='gap-7 w-fit justify-center mx-auto mt-7 flex flex-wrap h-52'>
              <PageLoader/>
            </div> :
            <>
              <div className='featuredProperty lg:w-10/12 mx-auto !pb-20'>
                {property?.map((investment: any, index) => {
                  return (
                      <div key={index}
                           className='rounded-3xl overflow-hidden xl:w-[285px] bg-white border border-custom-grey'>
                        <div className='relative'>
                          <img src={investment?.property?.thumbnail} alt='investment'
                               className='w-full h-44 bg-green-200'/>
                          <span
                              className={`absolute uppercase bg-white top-2 font-semibold left-2 rounded-full text-xs py-1 px-2.5 ${investment.property.available_slots === 0 ? 'text-red-500' : 'text-green-600'}`}>
                          {investment?.property.available_slots === 0 ? 'Sold Out' : 'Active'}
                        </span>
                        </div>
                        <div className='p-3'>
                          <h1 className='py-2'>{investment.property.details.property_title}</h1>
                          <div className={'flex gap-2 py-2'}>
                    <span
                        className={'bg-custom-grey flex justify-between gap-3 py-1 px-2.5 w-fit rounded-3xl items-center text-xs'}>
                      <span>
                        <Bed/>
                      </span>
                      <span>{investment.property.details.rooms_no}</span>
                    </span>
                            <span
                                className={'bg-custom-grey flex justify-between gap-3 py-1 px-2.5 w-fit rounded-3xl items-center text-xs'}>
                      <span>
                        <Shower/>
                      </span>
                      <span>{investment.property.details.showers}</span>
                    </span>
                            <span
                                className={'bg-custom-grey flex justify-between gap-3 py-1 px-2.5 w-fit rounded-3xl items-center text-xs'}>
                      <span>
                        <Toilet/>
                      </span>
                      <span>{investment.property.details.toilets_no}</span>
                    </span>
                          </div>
                          <p className='font-bold text-lg pb-2.5'>{formatCurrency(investment.property.total_investment_amount)}</p>
                          <p className='text-xs pb-2.5 text-custom'>{investment?.property?.get_address?.title}</p>
                          <div className='flex justify-between text-xs pb-1'>
                            <span>No Of Slots Available:</span>
                            <span
                                className={`font-semibold ${investment.property.available_slots === 0 ? 'text-red-500' : 'text-green-600'}`}>
                        {investment.property.available_slots === 0 ? 'Sold Out' : `${investment.property.available_slots} ${investment?.property?.available_slots === 1 ? 'Slot' : 'Slots'}`}
                      </span>
                          </div>
                          <div className='flex justify-between gap-1 pb-1.5'>
                            {Array.from({ length: investment.property.available_slots }).map((_, number) => (
                                <hr key={number}
                                    className={`h-2 w-8 rounded-full ${number < (0 - investment.property.available_slots) ? 'bg-custom-darkgreen' : 'bg-custom'}`}/>
                            ))}
                          </div>
                          <div className='flex justify-between text-xs pb-2.5'>
                            <span>Price Per Slot:</span>
                            <span
                                className={'font-semibold'}>{formatCurrency(investment.property.total_investment_amount)}</span>
                          </div>
                          <div className='flex justify-end'>
                            <Link to={`/app/properties/${investment.id}`}
                                  className='bg-custom-midnightgreen px-7 py-1 rounded-full text-white text-sm'>
                              View
                            </Link>
                          </div>
                        </div>
                      </div>
                  );
                })}
              </div>
            </>
        }
        <div className={'pb-12 flex justify-end lg:w-10/12 mx-auto'}>
          <Pagination paginate={paginate} getPropertyCoOwnData={GetHomeProperty}/>
        </div>
        <Footer/>
      </div>

  )
  ;
}

export default Property;
