import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useProperty } from '../../Services/hooks/PropertyCoOwn';
import { PageLoader } from '../../Components/Loader/pageLoader';
import { User } from '../../Storeredux/constants/actionTypes';
import { formatCurrency } from '../../Utils/currencyFormat';
import { ReactComponent as Bed } from '../../Assets/Icons/bedcardIcon.svg';
import { ReactComponent as Shower } from '../../Assets/Icons/ShowerIcon.svg';
import { ReactComponent as Toilet } from '../../Assets/Icons/toiletIcon.svg';
import { CustomSearch } from '../../Components/Form/CustomSearch';
import { Pagination } from '../../Components/Pagination/pagination';

function Portfolio() {
  const { getPropertyCoOwnData, loading: pageLoading, property, paginate, getFilteredPropertyData, error } = useProperty();
  const [filtered] = useState(false);

  const handleSearch = async (params: any) => {
    await getFilteredPropertyData(params);
  };

  useEffect(() => {
    getPropertyCoOwnData(1);
  }, []);
  return (
    <div className='py-9'>
      <div className='rounded-3xl bg-white w-11/12 mx-auto py-4 lg:px-16 px-8 '>
        <div className='flex lg:justify-evenly xl:justify-between flex-wrap gap-x-2 lg:gap-x-0 gap-y-3.5'>
          <CustomSearch onSearch={handleSearch}/>
          {/*<div className='flex gap-2 items-center'>*/}
          {/*    <label className="flex items-center cursor-pointer">*/}
          {/*        <div className="mr-3 text-xs md:text-sm font-semibold">Map View</div>*/}
          {/*        <div className="relative">*/}
          {/*            <input*/}
          {/*                type="checkbox"*/}
          {/*                id="toggle"*/}
          {/*                className="hidden"*/}
          {/*                checked={enabled}*/}
          {/*                onChange={() => setEnabled(!enabled)}*/}
          {/*            />*/}
          {/*            <div className={`toggle__line w-12 h-6 rounded-full shadow-inner ${*/}
          {/*                enabled ? 'bg-custom-midnightgreen' : 'bg-gray-400 '*/}
          {/*            }`}></div>*/}
          {/*            <div*/}
          {/*                className={`toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 ${*/}
          {/*                    enabled ? 'right-0' : 'left-0'*/}
          {/*                }`}*/}
          {/*            ></div>*/}
          {/*        </div>*/}
          {/*    </label>*/}
          {/*</div>*/}
          {/*<div>*/}
          {/*    <button*/}
          {/*        className='bg-custom-midnightgreen text-xs md:text-sm font-semibold rounded-custom py-2 px-6 text-white'>*/}
          {/*        Make an Investment*/}
          {/*    </button>*/}
          {/*</div>*/}
        </div>
        {error && (<p className='text-sm text-red-900 italic pt-1'>
          {error}
        </p>)}
        <div className='py-4'>
          <h1 className='font-semibold text-xl'>
            {!filtered ? 'All Properties ' : 'Search result in Lagos, Nigeria'}
          </h1>
          <p className='text-sm'>
            {property.length} properties
          </p>
        </div>
        {pageLoading ?
            <div className='gap-7 w-fit justify-center mx-auto mt-7 flex flex-wrap h-52'>
              <PageLoader/>
            </div>
          :
            <div className='gap-7 w-fit justify-center mx-auto mt-7 flex flex-wrap'>
              {property?.map((investment: User, index) => {
                return (
                    <div key={index}
                         className='rounded-3xl overflow-hidden w-[285px] sm:w-[200px] md:w-[285px] bg-white border border-custom-grey'>
                      <div className='relative'>
                        <img src={investment?.property.thumbnail} alt='investment'
                             className='w-full h-44 bg-green-200'/>
                        <span
                            className={`absolute uppercase bg-white top-2 font-semibold left-2 rounded-full text-xs py-1 px-2.5 ${investment?.property?.available_slots === 0 ? 'text-red-500' : 'text-green-600'}`}>
                          {investment?.property?.available_slots === 0 ? 'Sold Out' : 'Active'}
                        </span>
                      </div>
                      <div className='p-3'>
                        <h1 className='py-2'>{investment?.property?.details?.property_title}</h1>
                        <div className={'flex gap-2 py-2'}>
                        <span
                            className={'bg-custom-grey flex justify-between gap-3 py-1 px-2.5 w-fit rounded-3xl items-center text-xs'}>
                          <span>
                            <Bed/>
                          </span>
                          <span>{investment?.property?.details?.rooms_no}</span>
                        </span>
                          <span
                              className={'bg-custom-grey flex justify-between gap-3 py-1 px-2.5 w-fit rounded-3xl items-center text-xs'}>
                            <span>
                              <Shower/>
                            </span>
                            <span>{investment?.property?.details?.toilets_no}</span>
                          </span>
                          <span
                              className={'bg-custom-grey flex justify-between gap-3 py-1 px-2.5 w-fit rounded-3xl items-center text-xs'}>
                            <span>
                              <Toilet/>
                            </span>
                            <span>{investment?.property?.details?.toilets_no}</span>
                          </span>
                        </div>
                        <p className='font-bold text-lg pb-2.5'>{formatCurrency(investment?.property?.total_investment_amount)}</p>
                        <p className='text-xs pb-2.5 text-custom'>{investment?.property.get_address?.title}</p>
                        <div className='flex justify-between text-xs pb-1'>
                          <span>No Of Slots Available:</span>
                          <span
                              className={`font-semibold ${investment.property.available_slots === 0 ? 'text-red-500' : 'text-green-600'}`}>
                        {investment.property.available_slots === 0 ? 'Sold Out' : `${investment?.property?.available_slots} ${investment?.property?.available_slots === 1 ? 'Slot' : 'Slots'}`}
                      </span>
                        </div>
                        <div className='flex justify-between gap-1 pb-1.5'>
                          {Array.from({ length: investment?.property?.total_slots }).map((_, number) => (
                              <hr key={number}
                                  className={`h-2 w-8 rounded-full ${number < (investment?.property?.total_slots - investment.property.available_slots) ? 'bg-custom-darkgreen' : 'bg-custom'}`}/>
                          ))}
                        </div>
                        <div className='flex justify-between text-xs pb-2.5'>
                          <span>Price Per Slot:</span>
                          <span
                              className={'font-semibold'}>{formatCurrency(investment?.property?.total_investment_amount)}</span>
                        </div>
                        {/*<div className='flex justify-between text-xs pb-6'>*/}
                        {/*  <span>Annual Earnings:</span>*/}
                        {/*  <span className={'font-semibold'}>{investment.annualEarning}</span>*/}
                        {/*</div>*/}
                        <div className='flex justify-end'>
                          <Link to={`/app/properties/${investment?.property?.id}`}
                                className='bg-custom-midnightgreen px-7 py-1 rounded-full text-white text-sm'>
                            View
                          </Link>
                        </div>
                      </div>
                    </div>
                );
              })}
            </div>
        }
        <div className={'py-12 flex justify-end'}>
          <Pagination paginate={paginate}  getPropertyCoOwnData={getPropertyCoOwnData}/>
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
